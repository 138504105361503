import axios from 'axios'

function deleteLanguage(id) {
  return axios.delete(`language/${id}`)
}

function switchLanguageStatus(id, isWL=null) {
  let wl_code = axios.defaults.headers.wl_code
  if(isWL){
    axios.defaults.headers.wl_code = isWL
  }
  const response =  axios.put(`language/active-deactive/${id}`)
  axios.defaults.headers.wl_code = wl_code
  return response
}

function getAllLanguages(payload) {
  return axios.get('language/all', { params: payload })
}

function getLanguageById(id) {
  return axios.get(`language/${id}`)
}

function updateLanguage(id, data) {
  return axios.put(`language/${id}`, data)
}

function getLanguageDropdown(isWL=null) {
  let wl_code = axios.defaults.headers.wl_code
  if(isWL){
    axios.defaults.headers.wl_code = isWL
  }
  const response = axios.get('language')
  axios.defaults.headers.wl_code = wl_code
  return response
}

export default {
  deleteLanguage,
  switchLanguageStatus,
  getAllLanguages,
  getLanguageById,
  updateLanguage,
  getLanguageDropdown
}
