<template>
  <div>
    <!-- Table Container Card -->
    <!-- Active / Inactive hotel confirmation modal -->
    <confirm-disable
      ref="disableStatusModal"
      :msg="disableMsg"
      @on-submit="switchHotelStatus"
    />
    <!-- PriorTize add/remove hotel confirm modal -->
    <confirm-disable
      ref="disablePriortizeModal"
      :msg="disableMsg"
      @on-submit="addRemovePriortize"
      @on-hide="resetPriortizeStatus"
    />

    <b-card no-body>
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <b-col cols="12" md="3">
            <b-form-group
              label="Select Languages"
              label-for="mc-select-language"
            >
              <v-select
                v-model="selectedLang"
                :options="Languages"
                class="language"
                placeholder="Select Languages"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :reduce="(val) => val.value"
                :clearable="false"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="3">
            <b-form-group label="Select Status" label-for="mc-select-status">
              <v-select
                v-model="isActive"
                :options="statusFilter"
                class="mc-select-status"
                placeholder="Select Status"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :reduce="(val) => val.value"
                :clearable="false"
              />
            </b-form-group>
          </b-col>

          <!-- Search Hotel Id -->
          <b-col cols="12" md="6">
            <b-form-group
              label="Search Hotel ID"
              label-for="mc-search-hotel-ID"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="SearchIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    id="mc-search-hotel-ID"
                    v-model="searchHotelId"
                    placeholder="Search Hotel ID"
                  />
                </b-input-group>
              </div>
            </b-form-group>
          </b-col>

          <!-- Search Hotel Name -->
          <b-col cols="12" md="6">
            <b-form-group
              label="Search Hotel Name"
              label-for="mc-search-hotel-name"
            >
              <vue-autosuggest
                id="mc-search-hotel-name"
                ref="autocomplete"
                v-model="searchHotelName"
                :suggestions="suggestions"
                :input-props="inputProps"
                :section-configs="sectionConfigs"
                :render-suggestion="renderSuggestions"
                :get-suggestion-value="getSuggestionValue"
                @input="fetchResults"
              />
            </b-form-group>
          </b-col>

          <!-- Search Hotel City -->
          <b-col cols="12" md="6">
            <b-form-group
              label="Search Hotel City"
              label-for="mc-search-hotel-city"
            >
              <vue-autosuggest
                id="mc-search-hotel-city"
                ref="city_autocomplete"
                v-model="searchHotelCity"
                :suggestions="citySuggestions"
                :input-props="inputCityProps"
                :section-configs="sectionCityConfigs"
                :render-suggestion="renderCitySuggestions"
                :get-suggestion-value="getCitySuggestionValue"
                @input="fetchCityResults"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col cols="12" md="6" lg="6" sm="4" class="d-flex align-items-center justify-content-start mb-1 mb-md-0" >
            <label>Show {{ selected }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>
          <b-col cols="12" md="3" lg="3" sm="4">
            <div class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
              <label for="edited-checkbox" role="button"><strong>Filter by Edited hotels</strong></label>
              <b-form-checkbox
                id="edited-checkbox"
                v-model="isEdited"
                class="custom-control-primary m-1"
                switch
              >
                <span class="switch-icon-left">
                  <feather-icon icon="Edit3Icon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="DiscIcon" />
                </span>
              </b-form-checkbox>
            </div>
          </b-col>
          <b-col cols="12" md="3" lg="3" sm="4">
            <div class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
              <label for="prior-checkbox" role="button"><strong>Filter by Priority hotels</strong></label>
              <b-form-checkbox
                id="prior-checkbox"
                v-model="isPriortize"
                class="custom-control-primary m-1"
                switch
              >
                <span class="switch-icon-left">
                  <feather-icon icon="TrendingUpIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="DiscIcon" />
                </span>
              </b-form-checkbox>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        v-if="!contentLoading"
        ref="refContentListTable"
        class="position-relative"
        :items="items"
        responsive
        :search-options="{
          enabled: true,
          externalQuery: searchHotelId,
        }"
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(hero_image_thumbnail)="data">
          <img :src="data.value" v-bind="imgSize" @error="defaulImage" />
        </template>

        <template #cell(is_active)="data">
          <b-button
            :class="
              `cursor-pointer p-0 statusBtn bg-light-${resolveUserStatusVariant(
                data.item.is_active
              )} border-${resolveUserStatusVariant(data.item.is_active)}`
            "
            @click="
              openDisableStatusModal(
                data.item.unique_id,
                data.item.is_active,
                data.item.hotel_name
              )
            "
            >{{ resolveUserStatusName(data.item.is_active) }}</b-button
          >
        </template>

        <template #cell(is_priortize)="data">
          <div>
            <b-form-checkbox
              v-model="data.item.is_priortize"
              class="custom-control-primary"
              name="check-button"
              switch
              @change="
                openPriortizeModal(
                  data.item.unique_id,
                  data.item.is_priortize,
                  data.item.hotel_name
                )
              "
            >
              <span class="switch-icon-left">
                <feather-icon icon="TrendingUpIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="DiscIcon" />
              </span>
            </b-form-checkbox>
          </div>
        </template>

        <template #cell(city)="data">
          {{ data.item && data.item.city ? data.item.city : '-' }}
        </template>

         <template #cell(country)="data">
          {{ data.item && data.item.country ? data.item.country : '-' }}
        </template>

        <!-- Column: Actions -->
        <template #cell(action)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              :to="{
                name: 'hotel-content-edit',
                params: { id: data.item.unique_id },
                query: { page: currentPage, rows: perPage },
              }"
              :disabled="
                checkLoginRole() !== RoleEnum.SuperAdmin && !checkAccess.Edit
              "
            >
              <feather-icon icon="EditIcon" />
              <span v-if="data.item.is_edited" class="align-middle ml-50"
                >Edited</span
              >
              <span v-else class="align-middle ml-50">Edit</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div v-if="contentLoading">
        <Loader />
      </div>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ totalHotel >= 1 ? startIndex + 1 : startIndex }} to
              {{ endIndex > totalHotel ? totalHotel : endIndex }} of
              {{ totalHotel }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :per-page="perPage"
              :total-rows="totalHotel"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BInputGroup,
  BFormInput,
  BInputGroupPrepend,
  BTable,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormGroup,
  BBadge,
  BButton,
  BFormCheckbox,
} from "bootstrap-vue";
import vSelect from "vue-select";
import {
  perPageOptions,
  perPage,
  currentPage,
  resConditionCheck,
  RoleEnum,
  statusFilter,
  resolveUserStatusVariant,
  resolveUserStatusName,
} from "@/helpers/constant";
import errorResponseHandler from "@/services/errorHandler";
import languageService from "@/services/language/language.service";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import checkLoginRole from "@/helpers/checkLoginRole";
import accessRightCheck from "@/helpers/accessRightCheck";
import { VueAutosuggest } from "vue-autosuggest";
import axios from "axios";
import Loader from "@/layouts/skeloton-loader/Loader.vue";
import BeonTripsLogo from "../../../assets/images/logo/beontrips.png";
import HotelContentService from "../../../services/hotel-content/hotelContent.service";
import ConfirmDisable from "../../confirm-disable/ConfirmDisable.vue";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BInputGroup,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    BInputGroupPrepend,
    vSelect,
    VueAutosuggest,
    BFormGroup,
    Loader,
    BBadge,
    ConfirmDisable,
    BButton,
    BFormCheckbox,
  },

  data() {
    return {
      contentLoading: true,
      searchHotelId: "",
      searchCity: "",
      sortBy: "",
      perPageOptions,
      perPage,
      isSortDirDesc: false,
      currentPage,
      items: [],
      deleteMsg: "",
      RoleEnum,
      titleMsg: "",
      startIndex: 0,
      endIndex: 0,
      errorData: "",
      hotelId: "",
      totalHotel: 0,
      checkLoginRole,
      checkAccess: {},
      noResults: false,
      hotelData: "",
      tableColumns: [
        { key: "unique_id" },
        { key: "hero_image_thumbnail", label: "Thumbnail" },
        { key: "hotel_name", sortable: true },
        { key: "city", sortable: true },
        { key: "country", sortable: true },
        { key: "is_active", label: "STATUS" },
        { key: "is_priortize", label: "PRIORTIZE" },
        { key: "action" },
      ],
      imgSize: {
        blank: true,
        blankColor: "#777",
        width: 75,
        height: 75,
        class: "m1",
      },
      searchHotelName: "",
      searchHotelCity: "",
      results: [],
      timeout: null,
      selected: null,
      citySelected: null,
      debounceMilliseconds: 250,
      inputProps: {
        id: "autosuggest__input_ajax",
        placeholder: "Search Hotel Name",
        class: "form-control",
        name: "ajax",
      },
      sectionConfigs: {
        hotels: {
          limit: 50,
          label: "Select Hotel",
          onSelected: (selected) => {
            console.log("", selected);
            this.searchHotelId = selected.item.unique_id;
            this.getHotelContentListing();
            this.searchHotelId = "";
            // this.searchHotelName = ""
          },
        },
      },
      inputCityProps: {
        id: "autosuggest__input_city_ajax",
        placeholder: "Search Hotel City",
        class: "form-control",
        name: "ajax",
      },
      sectionCityConfigs: {
        cities: {
          limit: 100,
          label: "Select City",
          onSelected: (citySelected) => {
            console.log("----SELECTED", citySelected);
            this.searchCity = citySelected.item.city;
            this.getHotelContentListing();
            // this.searchCity = "";
            // this.searchHotelName = ""
          },
        },
      },
      suggestions: [],
      citySuggestions: [],
      selectedLang: "en",
      Languages: [],
      isActive: "", // [ 0 = false, 1 = true ]
      statusFilter,
      resolveUserStatusVariant,
      resolveUserStatusName,
      status2: "",
      disableMsg: "",
      uniqueID: "",
      isPrior: "",
      isPriortize: "",
      isEdited: "",
    };
  },

  watch: {
    currentPage: {
      handler(val) {
        this.getHotelContentListing();
        this.$router
          .push({
            name: "hotel-content-list",
            query: { page: val, rows: this.perPage },
          })
          .catch(() => {});
      },
    },

    perPage: {
      handler(val) {
        // this.currentPage = 1;
        this.getHotelContentListing();
        this.$router
          .push({
            name: "hotel-content-list",
            query: { page: this.currentPage, rows: val },
          })
          .catch(() => {});
      },
    },

    sortBy: {
      handler() {
        this.getHotelContentListing();
      },
    },

    searchHotelId: {
      handler() {
        this.getHotelContentListing();
      },
    },

    searchCity: {
      handler() {
        this.getHotelContentListing();
      },
    },

    isSortDirDesc: {
      handler() {
        this.getHotelContentListing();
      },
    },

    selectedLang: {
      handler() {
        this.currentPage = 1;
        this.getHotelContentListing();
      },
    },
    isActive: {
      handler() {
        this.currentPage = 1;
        this.getHotelContentListing();
      },
    },
    isPriortize: {
      handler() {
        this.currentPage = 1;
        this.getHotelContentListing();
      },
    },
    isEdited: {
      handler() {
        this.currentPage = 1;
        this.getHotelContentListing();
      },
    },
    searchHotelCity: {
      handler(val) {
        if(!val.length) {
          this.searchCity = "";
          this.currentPage = 1;
          this.getHotelContentListing();
        }
      },
    },
  },

  async mounted() {
    this.checkAccess = accessRightCheck(this.$route.meta.module.name);
    // if (this.checkLoginRole() !== RoleEnum.SuperAdmin && !this.checkAccess.Edit) {
    this.tableColumns = [
      { key: "unique_id" },
      { key: "hero_image_thumbnail", label: "Thumbnail" },
      { key: "hotel_name", sortable: true },
      { key: "city", sortable: true },
      { key: "country", sortable: true },
      { key: "is_active", label: "STATUS" },
      { key: "is_priortize", label: "PRIORTIZE" },
      { key: "action" },
    ];

    this.getLanguageDropdown();
    await this.getHotelContentListing();
    if (this.$route.query) {
      this.currentPage = this.$route.query.page ? this.$route.query.page : 1;
      this.perPage = this.$route.query.rows ? this.$route.query.rows : 10;
    }
  },

  methods: {
    async getHotelContentListing() {
      this.contentLoading = true;
      const payload = {
        page_no: this.currentPage,
        per_page: this.perPage,
        order: this.isSortDirDesc === true ? "DESC" : "ASC",
        order_by: this.sortBy,
        unique_id: this.searchHotelId,
        is_active: this.isActive ? 1 : this.isActive !== "" ? 0 : "",
        is_priortize: this.isPriortize ? 1 : "",
        is_edited: this.isEdited ? 1 : "",
        city: this.searchCity,
      };
      await HotelContentService.getAll(payload, this.selectedLang)
        .then((res) => {
          if (resConditionCheck(res.status) && res.data.data) {
            this.contentLoading = false;
            this.items = res.data.data;
            this.totalHotel = res.data.count;
            this.startIndex = this.currentPage * this.perPage - this.perPage;
            this.endIndex = this.startIndex + this.perPage;
          }
        })
        .catch((error) => {
          const errorData = errorResponseHandler(error);
          this.contentLoading = false;
          if (error.response.status === 404) {
            this.items = [];
            this.totalHotel = 0;
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorData,
                icon: "X-CircleIcon",
                variant: "danger",
              },
            });
          }
        });
    },

    defaulImage(e) {
      e.target.src = BeonTripsLogo;
    },
    // Hotel name filteration
    fetchResults() {
      const { searchHotelName } = this;
      if (searchHotelName.length < 3) {
        this.suggestions = [];
        this.getHotelContentListing();
      } else {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          axios.defaults.headers.common.language_code = this.selectedLang;
          const hotelPromise = axios.get(
            `hotel/search-hotel?term=${searchHotelName}`
          );
          Promise.all([hotelPromise])
            .then((values) => {
              this.suggestions = [];
              this.selected = null;
              const hotels = this.filterResults(
                values[0].data.data,
                searchHotelName,
                "name"
              );
              // eslint-disable-next-line no-unused-expressions
              hotels.length &&
                this.suggestions.push({
                  name: "hotels",
                  data: values[0].data.data,
                });
            })
            .catch((error) => {
              const errorData = errorResponseHandler(error);
              this.suggestions = [
                {
                  data: [
                    {
                      id: 0,
                      name: errorData,
                      unique_id: 0,
                    },
                  ],
                },
              ];
            });
        }, this.debounceMilliseconds);
      }
    },
    filterResults(data, text, field) {
      return data
        .filter((item) => {
          if (item[field].toLowerCase().indexOf(text.toLowerCase()) > -1) {
            return item[field];
          }
          return false;
        })
        .sort();
    },
    renderSuggestions(suggestion) {
      return suggestion.item.name;
    },
    getSuggestionValue(suggestion) {
      const { item } = suggestion;
      return item.name !== "No result found" ? item.name : "";
    },

    // City name filteration
    fetchCityResults() {
      const { searchHotelCity } = this;
      if (searchHotelCity.length < 3) {
        this.citySuggestions = [];
        this.getHotelContentListing();
      } else {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          axios.defaults.headers.common.language_code = this.selectedLang;
          const hotelPromise = axios.get(
            `hotel/search-city-dump?term=${searchHotelCity}`
          );
          Promise.all([hotelPromise])
            .then((values) => {
              this.citySuggestions = [];
              this.citySelected = null;
              const cities = this.filterCityResults(
                values[0].data.data,
                searchHotelCity,
                "city"
              );
              // eslint-disable-next-line no-unused-expressions
              cities.length &&
                this.citySuggestions.push({
                  name: "cities",
                  data: values[0].data.data,
                });
            })
            .catch((error) => {
              console.log("ERROR", error)
              const errorData = errorResponseHandler(error);
              this.citySuggestions = [ { data: [{ city: errorData }] },
              ];
            });
        }, this.debounceMilliseconds);
      }
    },
    filterCityResults(data, text, field) {
      console.log('------',data, text, field)
      return data
        .filter((item) => {
          if (item[field].toLowerCase().indexOf(text.toLowerCase()) > -1) {
            return item[field];
          }
          return false;
        })
        .sort();
    },
    renderCitySuggestions(suggestion) {
      return suggestion.item.city;
    },
    getCitySuggestionValue(suggestion) {
      const { item } = suggestion;
      return item.city !== "No result found" ? item.city : "";
    },

    async getLanguageDropdown() {
      try {
        const res = await languageService.getLanguageDropdown();
        if (resConditionCheck(res.status) && res.data.data) {
          this.Languages = res.data.data.map((e) => ({
            label: e.name,
            value: e.iso_1Code.toLowerCase(),
          }));
        }
      } catch (error) {
        const errorData = errorResponseHandler(error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: errorData,
            icon: "X-CircleIcon",
            variant: "danger",
          },
        });
      }
    },

    openDisableStatusModal(id, status, name) {
      this.uniqueID = id;
      this.status2 = !status;
      this.$refs.disableStatusModal.show();
      this.disableMsg = !status
        ? `Are you sure you want to activate <strong class="text-primary">${name}</strong> hotel ?`
        : `Are you sure you want to in-activate <strong class="text-primary">${name}</strong> hotel ?`;
    },
    resetStatus() {
      const foundIdex = this.items.findIndex(
        (item) => item.unique_id === this.uniqueID
      );
      this.$set(this.items[foundIdex], "is_active", this.status2);
    },
    switchHotelStatus(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$refs.disableStatusModal.loading = true;
      if (this.uniqueID) {
        HotelContentService.switchHotelStatus(this.uniqueID)
          .then((res) => {
            this.$refs.disableStatusModal.loading = false;
            this.$refs.disableStatusModal.toggleModal = false;
            this.resetStatus();
            if (resConditionCheck(res.status)) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: res.data.message,
                  icon: "CheckIcon",
                  variant: "success",
                },
              });
            }
          })
          .catch((error) => {
            this.$refs.disableStatusModal.loading = false;
            this.$refs.disableStatusModal.toggleModal = false;
            const errorData = errorResponseHandler(error);
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorData,
                icon: "X-CircleIcon",
                variant: "danger",
              },
            });
          });
      }
    },

    openPriortizeModal(id, status, name) {
      this.uniqueID = id;
      this.isPrior = !status;
      this.$refs.disablePriortizeModal.show();
      this.disableMsg = !status
        ? `Are you sure you want to remove priority of <strong class="text-primary">${name}</strong> hotel ?`
        : `Are you sure you want to prioritize <strong class="text-primary">${name}</strong> hotel ?`;
    },
    resetPriortizeStatus() {
      const foundIdex = this.items.findIndex(
        (item) => item.unique_id === this.uniqueID
      );
      this.$set(this.items[foundIdex], "is_priortize", this.isPrior);
    },

    addRemovePriortize(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$refs.disablePriortizeModal.loading = true;
      if (this.uniqueID) {
        HotelContentService.addRemoveToPriortizeHotelsList(this.uniqueID)
          .then((res) => {
            this.$refs.disablePriortizeModal.loading = false;
            this.$refs.disablePriortizeModal.toggleModal = false;
            if (resConditionCheck(res.status)) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: res.data.message,
                  icon: "CheckIcon",
                  variant: "success",
                },
              });
            }
          })
          .catch((error) => {
            this.$refs.disablePriortizeModal.loading = false;
            this.$refs.disablePriortizeModal.toggleModal = false;
            const errorData = errorResponseHandler(error);
            this.resetPriortizeStatus();
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorData,
                icon: "X-CircleIcon",
                variant: "danger",
              },
            });
          });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.statusBtn {
  border: 1px solid;
  padding: 2px 10px !important;
  font-weight: bolder;
  transition: 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.statusBtn:focus {
  background-color: #3f8d85!important;
  border-color: #3f8d85 !important
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-autosuggest.scss";
</style>
