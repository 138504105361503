import axios from 'axios'

function getAll(payload, language) {
  axios.defaults.headers.common.language_code = language
  return axios.get('hotel/hotel-list', {
    params: payload
  })
}

function getHotelContentById(payload) {
  return axios.get(`hotel/hotel-view/`, {
    params: payload
  })
}

function updateHotelContent(id, data) {
  return axios.put(`hotel/hotel-view/${id}`, data)
}

function deleteHotelImage(id, reqData) {
  return axios.delete(`hotel/delete-hotel-image/${id}`, {
    data: reqData
  })
}

function addHotelImage(id, data) {
  return axios.post(`hotel/upload-hotel-image/${id}`, data)
}

function addHotelReview(data, wlCode) {
  axios.defaults.headers.wl_code = wlCode
  return axios.post(`hotel/hotel-review`, data)
}

function getHotelReviews(data) {
  return axios.get(`hotel/reviews`, { params: data })
}

function getReviewById(id) {
  return axios.get(`hotel/review/${id}`)
}

function editReview(id, data, wlCode) {
  axios.defaults.headers.wl_code = wlCode
  return axios.put(`hotel/review/${id}`, data)
}

function deleteReview(id) {
  return axios.delete(`hotel/review/${id}`)
}

function switchHotelStatus(id) {
  return axios.put(`hotel/active-inactive/${id}`)
}

function addRemoveToPriortizeHotelsList(id) {
  return axios.put(`hotel/hotel-priortize/${id}`)
}

function updateHotelHeroImage(id, data) {
  return axios.put(`hotel/hotel-hero-image/${id}`, data)
}

function newDisableHotel(data) {
  return axios.post(`hotel/disable-hotel`, data)
}

function listDisabledHotels(data) {
  return axios.get(`hotel/list-disabled-hotel`, { params: data })
}

function switchDisableStatus(id) {
  return axios.put(`hotel/switch-disable-status/${id}`)
}

function getDisableHotel(id) {
  return axios.get(`hotel/disable-hotel-details/${id}`)
}

function editDisabledHotel(id, data) {
  return axios.put(`hotel/edit-disable-hotel/${id}`, data)
}

function deleteDisabledHotel(id) {
  return axios.delete(`hotel/delete-disable-hotel/${id}`)
}

export default {
  getAll,
  getHotelContentById,
  updateHotelContent,
  deleteHotelImage,
  addHotelImage,
  addHotelReview,
  getHotelReviews,
  getReviewById,
  editReview,
  deleteReview,
  switchHotelStatus,
  addRemoveToPriortizeHotelsList,
  updateHotelHeroImage,
  newDisableHotel,
  listDisabledHotels,
  switchDisableStatus,
  getDisableHotel,
  editDisabledHotel,
  deleteDisabledHotel
}
